import { AltLayout } from "layouts/AltLayout";
import { theme } from "@/GlobalStyles";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { ImgZoom } from "./ImgZoom";

const Images = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1030px;
  margin: 0 auto;
  padding: 0 16px;
  gap: 20px;
  padding-bottom: 60px;

  @media (max-width: 1023px) {
    gap: 5px;

    > button > .gatsby-image-wrapper {
      width: 100%;
      height: calc((100vw - 42px) / 3);
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 20px 0 120px;

    > button > .gatsby-image-wrapper {
      border-radius: 100px;
    }
  }

  @media (min-width: 1280px) {
    gap: 20px;
  }
`;

const OfficeTourPage = () => {
  return (
    <div>
      <AltLayout>
        <AlternativeHeroBanner
          IntroHeader="Let’s take a virtual tour!"
          NavigateTo="Office Tour"
          NavigateFrom="Home"
          PageName="Office Tour"
          BgHero="office-tour-hero.jpg"
          introHeadColor={theme.colors.tomatoRed}
        />
        <Images>
          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/01.jpg"
                alt="doctor sam and doctor blerta"
                width={1515}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/01.jpg"
              alt="doctor sam and doctor blerta"
              width={505}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/02.jpg"
                alt="doctor sam and doctor blerta"
                width={885}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/02.jpg"
              alt="doctor sam and doctor blerta"
              width={295}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/03.jpg"
                alt="pearl pediatric dentistry office"
                width={570}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/03.jpg"
              alt="pearl pediatric dentistry office"
              width={190}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/04.jpg"
                alt="pearl pediatric dentistry office"
                width={885}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/04.jpg"
              alt="pearl pediatric dentistry office"
              width={295}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/05.jpg"
                alt="doctor sam and doctor blerta"
                width={885}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/05.jpg"
              alt="doctor sam and doctor blerta"
              width={295}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/06.jpg"
                alt="pearl pediatric dentistry office"
                width={1200}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/06.jpg"
              alt="pearl pediatric dentistry office"
              width={400}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/07.jpg"
                alt="pearl pediatric dentistry office"
                width={1200}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/07.jpg"
              alt="pearl pediatric dentistry office"
              width={400}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/08.jpg"
                alt="doctor sam and doctor blerta"
                width={1830}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/08.jpg"
              alt="doctor sam and doctor blerta"
              width={610}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/09.jpg"
                alt="doctor sam and doctor blerta"
                width={1515}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/09.jpg"
              alt="doctor sam and doctor blerta"
              width={505}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/10.jpg"
                alt="sunflower painting"
                width={570}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/10.jpg"
              alt="sunflower painting"
              width={190}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/11.jpg"
                alt="pearl pediatric dentistry office"
                width={885}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/11.jpg"
              alt="pearl pediatric dentistry office"
              width={295}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/12.jpg"
                alt="flowers"
                width={570}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/12.jpg"
              alt="flowers"
              width={190}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/13.jpg"
                alt="pearl pediatric dentistry office"
                width={1830}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/13.jpg"
              alt="pearl pediatric dentistry office"
              width={610}
              height={310}
            />
          </ImgZoom>

          <ImgZoom
            img={
              <StaticImage
                src="../../images/office-tour/14.jpg"
                alt="doctor sam and doctor blerta"
                width={570}
                height={930}
              />
            }
          >
            <StaticImage
              src="../../images/office-tour/14.jpg"
              alt="doctor sam and doctor blerta"
              width={190}
              height={310}
            />
          </ImgZoom>
        </Images>
      </AltLayout>
    </div>
  );
};

export default OfficeTourPage;
