import ContactForm from "@/common/contact-form";
import GetInTouch from "@/common/get-in-touch";

interface AltLayoutProps {
  children?: React.ReactNode;
}

export const AltLayout: React.FC<AltLayoutProps> = ({ children }) => {
  return (
    <main
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {children}
      <GetInTouch />
      <ContactForm />
    </main>
  );
};
