import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOpen,
} from "../DialogPrimitive";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as ZoomIcon } from "./zoom.svg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode } from "react";

const DialogBtn = styled.button<{ close?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
  z-index: 2;

  ${({ close }) =>
    close
      ? css`
          position: absolute;
          right: 20px;
          top: 20px;
          border-radius: 50%;
          transition: background-color 0.3s ease-in-out;
          background-color: rgba(15, 17, 21, 0.4);

          @media (max-width: 1023px) {
            right: 7px;
            top: 7px;
            > svg {
              width: 20px;
              height: 19px;
            }
          }

          :hover {
            background-color: rgba(15, 17, 21, 0.6);
          }

          :focus-visible {
            background-color: rgba(15, 17, 21, 0.6);
          }
        `
      : css`
          > .gatsby-image-wrapper + svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: rgba(15, 17, 21, 0.6);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            padding: 9px;
          }

          :hover {
            > .gatsby-image-wrapper + svg {
              opacity: 1;
            }
          }

          :focus-visible {
            > .gatsby-image-wrapper + svg {
              opacity: 1;
            }
          }
        `};
`;

const contentStyles = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: max-content;

  > div {
    max-width: 90vw;
    max-height: 90vh;
  }
`;

const overlayStyles = css`
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ImgZoom = ({
  children,
  img,
}: {
  children: ReactNode;
  img: ReactNode;
}) => {
  return (
    <Dialog>
      <DialogOpen>
        <DialogBtn>
          {children}

          <ZoomIcon />
        </DialogBtn>
      </DialogOpen>

      <DialogContent
        contentStyles={contentStyles}
        overlayStyles={overlayStyles}
        title="image modal"
      >
        <DialogClose>
          <DialogBtn aria-label="close this image modal" close>
            <CloseIcon />
          </DialogBtn>
        </DialogClose>
        {img}
      </DialogContent>
    </Dialog>
  );
};
